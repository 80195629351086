import { Flex, Heading, Image } from "@chakra-ui/react";
import QrCodeLocation from "../../images/qrcode_location.png";
import './location.css';

export const Location = () => (
    <Flex pb={10} pt={[4, 0]} flexDir="column">
        <center>
            <Heading as="h2" fontSize={40} color="#EA28EA" py={4} alignItems="center">Location</Heading>
        </center>
        <Flex justifyContent="space-evenly" flexDir={[ "column", "row" ]}>
            <Image src={QrCodeLocation} alt="QR Code Location" height="300" mb={[8, 0]} />
            <center>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4265.039731620535!2d34.9765556!3d0.4255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMjUnMzEuOCJOIDM0wrA1OCczNS42IkU!5e1!3m2!1sen!2ske!4v1676804473196!5m2!1sen!2ske"
                style={{ border:0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="mapLocation"
            ></iframe>
            </center>
        </Flex>
    </Flex>
)
