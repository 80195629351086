import { Flex, Image, Box, Heading, Text } from "@chakra-ui/react";
import NicNicky from "../../images/nic_nicky.png";

export const KoitoContent = () => (
    <Flex flexDir={["column", "row"]} justifyContent="space-between">
        <Box width={[ "98%", "49%" ]} pt={[8, 0]}>
            <Image
                src={NicNicky}
                alt="nic_nicky"
                height={[ "300", "400" ]}
                boxShadow="4px 4px 20px #9747FF"
                borderBottom="7px solid purple"
                borderRadius="5px"
                m={[ 0, 4 ]}
            />
        </Box>
        <Box width={[ "96%", "46%" ]} pt={[4, 0]}>
            <Heading as="h2" fontSize={48} color="#EA28EA">Nic & Nicky</Heading>
            <Text fontFamily="Pacifico, cursive" fontSize="21px">Engangement & Farewell Party</Text>
            <Text py={8} w="80%">
                We together with our families do have the pleasure to invite you to share the enjoyment of our engagement and
                farewell party that will happen on <b>Thursday, 27th April, 2023.</b> <br /><br /><b>You are highly welcomed.</b>
            </Text>
            <Flex alignItems="center" justifyContent="space-between">
                <Text
                    borderLeft="4px solid #EA28EA" 
                    borderRadius={2}
                    pl={1}
                >
                    Luchin Village, <br />Mosop Constituency, <br /> Nandi County
                </Text>
                <Text
                    borderLeft="4px"
                    borderRadius={2}
                    borderColor="#EA28EA"
                    fontWeight="bold"
                    pl={1}
                    fontSize={22}
                    bottom="0"
                >
                    April, 27th
                </Text>
            </Flex>
        </Box>
    </Flex>
)